<template>
	<div class="main-card mb-3 card pa-0 ma-0">
		<v-card>
			<v-card-text class="pa-2 ma-0">
				<v-form ref="form" lazy-validation>
					<v-row>
						<v-col cols="12">
							<label class="mt-2 title"> {{ $t("generic.lang_mandatoryOptions") }} </label>
						</v-col>
						<v-col cols="12">
							<div class="my-1" style="height: 40px !important">
								<v-text-field
									v-model="formData.name"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									@focus="showTouchKeyboard"
									:label="$t('generic.lang_name')"
									:rules="[(v) => !!v]"
									dense
									outlined
								/>
							</div>
						</v-col>
						<v-col cols="12"
							><div class="my-1" style="height: 40px !important">
								<BaseTimeInput
									v-model="formData.maxTime"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									@focus="showTouchKeyboard"
									:label="$t('generic.lang_reservationTime')"
									:rules="[(v) => !!v, (v) => v !== '00:00' && v !== '00:']"
									dense
									outlined
								/></div
						></v-col>
						<v-col cols="12"
							><div class="my-1" style="height: 40px !important">
								<v-text-field
									v-model="formData.maxPerson"
									:data-layout="KEYBOARD.KEYSETS.NORMAL"
									@focus="showTouchKeyboard"
									:label="$t('generic.lang_maxPersons')"
									:rules="[(v) => !!v, (v) => parseInt(v) > 0]"
									:min="1"
									dense
									outlined
									type="number"
								/></div
						></v-col>
						<v-col cols="12">
							<label class="mt-2 title"> {{ $t("generic.lang_additionalOptions") }} </label>
						</v-col>
						<v-col cols="6">
							<div class="my-1" style="height: 40px !important">
								<icon-picker
									v-model="formData.icon"
									dense
									:field-label="$t('generic.lang_chooseIcon')"
								/></div
						></v-col>
						<v-col cols="6">
							<div class="my-1" style="height: 40px !important">
								<color-picker
									v-model="formData.color"
									dense
									:label="$t('erp.lang_warecreate_color')"
									outlined
									v-bind:color="formData.color"
								/></div
						></v-col>
						<v-col cols="6">
							<div class="my-1" style="height: 40px !important">
								<BaseDateInput
									v-model="formData.date"
									:label="$t('generic.lang_date')"
									dense
									outlined
									clearable
								/></div
						></v-col>
						<v-col cols="6">
							<div class="my-1" style="height: 40px !important">
								<v-text-field
									v-model="formData.cleaning_time"
									type="number"
									:min="0"
									dense
									outlined
									:label="$t('generic.lang_preparingTime')"
									:hint="$t('reservation.lang_theRangeTimeYouNeedToBeReadyForTheNextReservation')"
								>
									<template v-slot:append>
										<span class="mt-1">
											{{ $t("generic.lang_minutes") }}
										</span>
									</template>
									<template v-slot:append-outer>
										<v-tooltip top nudge-left="60">
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
											</template>
											<span>
												{{ $t("reservation.lang_reservationPreparingTimeTooltip") }}
											</span>
										</v-tooltip>
									</template>
								</v-text-field>
							</div></v-col
						>
						<v-col cols="12">
							<div>
								<v-row>
									<div class="d-flex">
										<v-switch
											v-model="formData.skipOrders"
											class="pl-8 pr-1"
											:label="$t('generic.lang_skipOrders')"
										></v-switch>

										<v-tooltip top nudge-bottom="30" nudge-left="70">
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
													mdi-information
												</v-icon>
											</template>
											<span>
												{{ $t("reservation.lang_reservationSkipOrdersTooltip") }}
											</span>
										</v-tooltip>
									</div>

									<div class="d-flex">
										<v-switch
											v-model="formData.skipTable"
											class="pl-8 pr-1"
											:label="$t('generic.lang_skipTable')"
										></v-switch>

										<v-tooltip top nudge-bottom="30" nudge-left="70">
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
													mdi-information
												</v-icon>
											</template>
											<span>
												{{ $t("reservation.lang_reservationSkipTableTooltip") }}
											</span>
										</v-tooltip>
									</div>

									<div class="d-flex">
										<v-switch
											v-model="formData.can_set_end_time"
											class="pl-8 pr-1"
											:label="$t('generic.lang_employeeSetEndtime')"
										></v-switch>

										<v-tooltip top nudge-bottom="30" nudge-left="70">
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
													mdi-information
												</v-icon>
											</template>
											<span>
												{{ $t("reservation.lang_reservationEmployeeSetEndtimeTooltip") }}
											</span>
										</v-tooltip>
									</div>

									<div class="d-flex">
										<v-switch
											v-model="formData.disableRequiredFields"
											class="pl-8 pr-1"
											:label="$t('generic.lang_disableRequiredFields')"
										></v-switch>

										<v-tooltip top nudge-bottom="30" nudge-left="70">
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
													mdi-information
												</v-icon>
											</template>
											<span>
												{{ $t("reservation.lang_reservationDisableRequiredFieldsTooltip") }}
											</span>
										</v-tooltip>
									</div>

									<div class="d-flex">
										<v-switch
											v-model="formData.isBookingOnline"
											class="pl-8 pr-1"
											:label="$t('reservation.lang_bookingOnline')"
										></v-switch>

										<v-tooltip top nudge-bottom="30" nudge-left="70">
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
													mdi-information
												</v-icon>
											</template>
											<span>
												{{ $t("reservation.lang_reservationBookingOnlineTooltip") }}
											</span>
										</v-tooltip>
									</div>

									<div class="d-flex">
										<v-switch
											v-model="formData.confirmedByDefaulT"
											class="pl-8 pr-1"
											:label="$t('reservation.lang_confirmedByDefault')"
										></v-switch>

										<v-tooltip top nudge-bottom="30" nudge-left="70">
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
													mdi-information
												</v-icon>
											</template>
											<span>
												{{ $t("reservation.lang_reservationConfirmedByDefaulTTooltip") }}
											</span>
										</v-tooltip>
									</div>
								</v-row>
							</div>
						</v-col>
						<v-col cols="12">
							<v-text-field
								v-model="formData.confirmationEmailAddress"
								:data-layout="KEYBOARD.KEYSETS.NORMAL"
								@focus="showTouchKeyboard"
								type="email"
								dense
								outlined
								:label="$t('generic.lang_emailAdresse')"
								:hint="$t('reservation.lang_SendCopyOfReservationConfirmation')"
								:rules="[(v) => !v || /.+@.+\..+/.test(v) || $t('generic.lang_InvalidEmailAddress')]"
							>
								<template v-slot:append-outer>
									<v-tooltip top nudge-left="60">
										<template v-slot:activator="{ on, attrs }">
											<v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
										</template>
										<span>
											{{ $t("reservation.lang_EmailReservationConfirmationTooltip") }}
										</span>
									</v-tooltip>
								</template>
							</v-text-field>
						</v-col>
						<v-col cols="12">
							<v-expansion-panels>
								<v-expansion-panel>
									<v-expansion-panel-header>
										{{ $t("erp.lang_rooms") }}
										<v-tooltip top nudge-right="400">
											<template v-slot:activator="{ on, attrs }">
												<v-icon
													class="mr-5 justify-end align-content-end text-right"
													color="primary"
													dark
													size="20"
													v-bind="attrs"
													v-on="on"
												>
													mdi-information
												</v-icon>
											</template>
											<span>
												{{ $t("reservation.lang_reservationRoomsTooltip") }}
											</span>
										</v-tooltip>
									</v-expansion-panel-header>
									<v-expansion-panel-content>
										<v-checkbox
											v-model="formData.rooms"
											@change="$forceUpdate()"
											v-for="room in rooms"
											:key="room[0]"
											:label="room[1]"
											:value="room[0]"
										/>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-col>
						<v-col cols="12">
							<v-divider class="pa-0 ma-0" />
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>
			<v-container fluid :style="this.$vuetify.theme.dark ? 'background-color: #1E1E1E;' : ''">
				<v-layout>
					<v-flex class="text-right">
						<v-btn :disabled="loading" @click="goBacktoItem" color="error" text
							>{{ $t("generic.lang_prev") }}
						</v-btn>
						<v-btn :disabled="loading" @click="addData" color="success">{{ $t("generic.lang_add") }} </v-btn>
					</v-flex>
				</v-layout>
			</v-container>
			<div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
				<vue-touch-keyboard
					v-if="touchKeyboard.visible"
					id="onScreenKeyboard"
					:accept="hideTouchKeyboard"
					:cancel="hideTouchKeyboard"
					:defaultKeySet="touchKeyboard.keySet"
					:input="touchKeyboard.input"
					:layout="touchKeyboard.layout"
					:options="touchKeyboard.options"
					class="internalWidthExpanded"
				/>
			</div>
		</v-card>
	</div>
</template>
<script>
import BaseTimeInput from "@/components/common/BaseTimeInput.vue";
import BaseDateInput from "@/components/common/BaseDateInput.vue";
import iconPicker from "@/components/common/iconPicker";
import ColorPicker from "@/components/common/colorPicker";
import mixin from "../../../mixins/KeyboardMixIns";
import { ENDPOINTS } from "../../../config";
import { Events } from "@/plugins/events";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fal, fas);

export default {
	mixins: [mixin],
	components: {
		iconPicker,
		ColorPicker,
		BaseTimeInput,
		BaseDateInput,
		"font-awesome-icon": FontAwesomeIcon,
	},
	data() {
		return {
			rooms: [],
			groups: [],
			loading: false,
			formData: {
				name: "",
				maxTime: "",
				maxPerson: null,
				icon: "",
				color: "",
				date: null,
				cleaning_time: 0,
				skipOrders: false,
				skipTable: false,
				can_set_end_time: false,
				disableRequiredFields: false,
				isBookingOnline: false,
				confirmedByDefaulT: false,
				confirmationEmailAddress: "",
				rooms: [],
			},
			itemsDatatableHeaders: [
				{
					text: this.$t("erp.lang_itemgroup_name"),
					value: "group",
				},
				{
					text: this.$t("generic.lang_name"),
					value: "name",
					sortable: false,
				},
				{
					text: this.$t("erp.lang_ware_create_id"),
					value: "identcode",
					sortable: false,
				},
				{
					text: this.$t("generic.lang_menuType"),
					value: "menuTypeUuid",
					sortable: false,
				},
				{
					text: this.$t("generic.lang_serviceType"),
					value: "serviceTypesUuid",
					sortable: false,
				},
				{
					text: this.$t("settings.lang_settings_activ"),
					value: "selected",
				},
			],
		};
	},
	async mounted() {
		this.groups = await this.$store.dispatch("itemgroups/getItemgroups");
		this.getAllRooms();
	},
	methods: {
		goBacktoItem: function () {
			this.$router.push("/reservation/settings");
		},
		addData: function () {
			if (!this.$refs.form.validate()) {
				this.$vuetify.goTo(0);
				return;
			}
			this.loading = true;

			let typeClone = Object.assign({}, this.formData);
			typeClone.maxTime =
				parseInt(typeClone.maxTime.split(":")[0]) * 3600 + parseInt(typeClone.maxTime.split(":")[1]) * 60;

			this.axios
				.post(ENDPOINTS.RESERVATION.SETTINGS.TYPES.UPDATE, {
					types: [typeClone],
				})
				.then((res) => {
					if (res.status === 200) {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_ActionSuccessful"),
							color: "success",
						});
					} else if (res.data.STATUS === "FAILED") {
						Events.$emit("showSnackbar", {
							message: res.data.msg,
							color: "error",
						});
					} else {
						Events.$emit("showSnackbar", {
							message: this.$t("generic.lang_errorOccurred"),
							color: "error",
						});
					}
				})
				.finally(() => {
					this.loading = false;
					this.goBacktoItem();
				});
		},
		getAllRooms() {
			this.loadingRooms = true;
			this.axios
				.post("get/settings/allRooms/")
				.then((res) => {
					if (res.status === 200) {
						this.rooms = res.data.roomInformations;
						this.loadingRooms = false;
					}
				})
				.catch((err) => {
					this.loadingRooms = false;
				});
		},
	},
};
</script>
<style scoped>
details > summary::-webkit-details-marker {
	display: none;
}

details:active,
summary:active,
details:focus,
summary:focus {
	outline: none !important;
	border: none !important;
}

details {
	background: #fff;
	font-size: 16px;
	border-bottom: 1px solid #e0e0e0;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
	display: flex;
}

details div.content {
	padding: 0px 24px 24px 24px;
}

details[open] summary:after {
	color: #9e9e9e;
	content: "expand_less";
	font-family: "Material Icons";
}

details[open] {
	border: none;
	border-radius: 2px;
}

details[open]:first-child {
	margin-top: 0;
	margin-bottom: 16px;
	border: none;
}

details[open]:last-child {
	margin-top: 16px;
	margin-bottom: 0px;
	border: none;
}

summary {
	outline: none;
	cursor: pointer;
	padding: 16px 24px;
	color: #212121;
	position: relative;
	font-size: 15px;
}

summary:hover {
	background: #eeeeee;
}

@media (prefers-color-scheme: dark) {
	summary:hover {
		background: none;
	}
}

details[open] summary:hover {
	background: none;
}

summary::after {
	content: "expand_more";
	font-family: "Material Icons";
	color: #9e9e9e;
	font-size: 1.5em;
	padding: 0;
	text-align: center;
	margin-top: -5px;
	position: absolute;
	top: calc(50% - 12px);
	bottom: 0;
	right: 0;
	width: 6%;
}

summary {
	list-style-type: none;
}

.title {
	margin-top: 5px;
	font-size: 15px;
	color: #444;
	word-spacing: 1px;
	font-weight: normal;
	letter-spacing: 2px;
	text-transform: uppercase;
	font-weight: 500;

	display: grid;
	grid-template-columns: 1fr max-content 1fr;
	grid-template-rows: 27px 0;
	grid-gap: 20px;
	align-items: center;
	height: 30px !important;
}

.title:after,
.title:before {
	content: " ";
	display: block;
	border-bottom: 1px solid #ccc;
	border-top: 1px solid #ccc;
	height: 5px;
	background-color: #f8f8f8;
}
</style>
