<template>
	<div>
		<page-title
			:heading="$t('reservation.lang_createNewType')"
			show-previous-button
			:subheading="$t('reservation.lang_createNewType')"
			:icon="icon"
		></page-title>
		<div class="app-main__inner">
			<CreateType />
		</div>
	</div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import CreateType from "../../../components/reservation/settings/CreateType.vue";

export default {
	components: {
		PageTitle,
		CreateType,
	},
	data: () => ({
		icon: "pe-7s-plugin icon-gradient bg-tempting-azure",
	}),
};
</script>
